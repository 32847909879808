import React, { useEffect } from 'react';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { PromotionType } from '@laminar-product/client-commons-core/core';
import Button from 'components/Button';
import FormContainer from 'components/FormContainer';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { errorNotify } from 'utils/errorNotify';
import {
  BasicPromotion,
  DiscountType,
  PromotionForm,
  PromotionRoutes,
} from 'types/promotions';
import { createPromotion } from 'actions/promotions';
import moment from 'moment';
import notification from 'utils/notification';
import { captureError } from 'utils/captureError';
import { PaymentGateway } from 'types/plan';
import styles from './index.module.scss';
import PromotionsValidity from './PromotionsValidity';
import PromotionModalFields from './PromotionModalFields';

interface PromotionModalProps {
  promotionType: PromotionType;
  onCancel: () => void;
  redirectTo: PromotionRoutes;
}

const PromotionModal = ({
  promotionType,
  onCancel,
  redirectTo,
}: PromotionModalProps) => {
  const formMethods = useForm<BasicPromotion>({ mode: 'onChange' });
  const { getValues, reset, handleSubmit } = formMethods;
  const { push } = useHistory();

  useEffect(() => {
    if (!getValues('name')) {
      reset({
        discountType: DiscountType.PERIOD,
      });
    }
  }, [getValues, reset]);

  const [save, isSavingPromotion] = useSendAction<void, PromotionForm>(
    async () => {
      const formValues = getValues();
      const gatewayType = formValues.gatewayType as PaymentGateway;
      const externalId = formValues.externalId;
      const paymentMethod = formValues.paymentMethod;
      const promotionToSave: BasicPromotion = {
        name: formValues.name,
        discountType: formValues.discountType,
        amount:
          formValues.discountType === DiscountType.AMOUNT
            ? (formValues.amount || 0) * 100
            : formValues.amount,
        startDate: moment(formValues.startDate).format(),
        endDate: moment(formValues.endDate).format(),
        promotionType: promotionType,
        gatewayType,
      };

      if (gatewayType === PaymentGateway.RAZORPAY) {
        promotionToSave['externalId'] = externalId!;
        promotionToSave['paymentMethod'] = paymentMethod!;
      }
      const data = await createPromotion({ form: promotionToSave });
      notification.success({
        title: 'Success',
        description: 'Promotion has been saved',
      });
      push(`/${redirectTo}/${data.uuid}/`);
    },
    {
      onError: (error) => {
        errorNotify(error);
        captureError(error);
      },
    }
  );

  return (
    <FormProvider {...formMethods}>
      <FormContainer>
        <form onSubmit={handleSubmit(save)}>
          <fieldset>
            <PromotionModalFields />
            <PromotionsValidity />
            <div className={styles.modalButtons}>
              <Button
                htmlType="submit"
                type="primary"
                loading={isSavingPromotion}
              >
                Create
              </Button>

              <Button type="default" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </fieldset>
        </form>
      </FormContainer>
    </FormProvider>
  );
};

export default PromotionModal;
