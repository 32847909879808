import React, { useCallback, useMemo } from 'react';
import { Collapse, Input, Modal } from 'antd';
import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import LanguageChooser from 'components/LanguageChooser';
import ButtonsRow from 'components/ButtonsRow';
import { useAllLanguages } from 'hooks/useLanguages';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DiscountType, Promotion, PromotionForm } from 'types/promotions';
import moment from 'moment';
import notification from 'utils/notification';
import { errorNotify } from 'utils/errorNotify';
import { captureError } from 'utils/captureError';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { savePromotion } from '../promotionUtils/promotions';
import styles from './index.module.scss';
const TextArea = Input.TextArea;
interface MetaDataItem {
  name: string;
  description: string;
}

export interface CurrentItem {
  selectedLanguage?: string;
  name?: string;
  description?: string;
}

export interface MetaDataItems {
  [selectedLanguage: string]: MetaDataItem;
}

interface MetaDataProps {
  detailsModalVisible: boolean;
  setDetailsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  promotion?: Promotion;
  setItems: React.Dispatch<React.SetStateAction<MetaDataItems | undefined>>;
  items: MetaDataItems | undefined;
  selectedItem?: CurrentItem;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<CurrentItem | undefined>
  >;
}

const MetaData = ({
  detailsModalVisible,
  setDetailsModalVisible,
  setItems,
  items,
  promotion,
  selectedItem,
  setSelectedItem,
}: MetaDataProps) => {
  const selectedLanguages = useMemo(() => [], []);

  const { errors, control, watch, setValue, getValues } = useFormContext();
  const { getLanguageNameByIsoCode } = useAllLanguages();

  const [notifyPromotionUpdate] = useSendAction<void, PromotionForm>(
    async () => {
      notification.success({
        title: 'Success',
        description: 'Details has been updated successfully',
      });
    },
    {
      onError: (error) => {
        errorNotify(error);
        captureError(error);
      },
    }
  );

  const updateMetadata = useCallback(
    async (updatedItems: MetaDataItems) => {
      const formValues = getValues();
      let promotionToSave: Promotion = {
        name: formValues.name,
        discountType: formValues.discountType,
        amount:
          formValues.discountType === DiscountType.AMOUNT
            ? (formValues.amount || 0) * 100
            : formValues.amount,
        startDate: moment(promotion?.startDate).toISOString(),
        endDate: moment(promotion?.endDate).toISOString(),
        promotionType: formValues.promotionType,
        gatewayType: formValues.gatewayType,
        externalId: formValues.externalId,
        paymentMethod: formValues.paymentMethod,
        metadata: updatedItems,
        uuid: promotion?.uuid!,
        status: promotion?.status!,
      };

      await savePromotion(
        promotionToSave,
        promotion?.uuid!,
        promotion?.promotionType!
      );
      notifyPromotionUpdate(promotionToSave);
    },
    [getValues, notifyPromotionUpdate, promotion]
  );

  const handleSave = useCallback(async () => {
    const selectedLanguage =
      selectedItem?.selectedLanguage || watch('selectedLanguage') || 'en';

    const values: MetaDataItem = {
      name: watch('metaDataName'),
      description: watch('metaDataDescription'),
    };

    const updatedItems = {
      ...items,
      [selectedLanguage]: values,
    };

    setItems(updatedItems);
    await updateMetadata(updatedItems);
    setDetailsModalVisible(false);
  }, [
    watch,
    selectedItem,
    items,
    setItems,
    updateMetadata,
    setDetailsModalVisible,
  ]);

  const handleDelete = useCallback(
    (event: React.MouseEvent, key: string) => {
      event.stopPropagation();
      const newItems = { ...items };
      delete newItems[key];
      if (Object.keys(newItems).length === 0) {
        return notification.error({
          description: 'Error: Please add at least one translation',
        });
      } else {
        setItems(newItems);
        updateMetadata(newItems);
      }
    },
    [items, setItems, updateMetadata]
  );

  const handleEdit = useCallback(
    (event: React.MouseEvent, key: string, items: MetaDataItems) => {
      setSelectedItem({});
      event.stopPropagation();

      setSelectedItem({
        selectedLanguage: key,
        name: items[key].name,
        description: items[key].description,
      });
      setDetailsModalVisible(true);
    },

    [setDetailsModalVisible, setSelectedItem]
  );

  return (
    <>
      {items &&
        Object.keys(items).length > 0 &&
        Object.entries(items).map(([key, value]) => (
          <Collapse expandIconPosition="right">
            <Collapse.Panel
              header={getLanguageNameByIsoCode(key)}
              key={key}
              extra={
                <div className={styles.collapseExtras}>
                  <DeleteOutlined
                    onClick={(event) => handleDelete(event, key)}
                  />
                  <EditOutlined
                    onClick={(event) => handleEdit(event, key, items)}
                  />
                </div>
              }
            >
              <>
                <div className={styles.metadataDetails}>
                  <span>Plan name</span>
                  <p>{value.name}</p>
                </div>
                <div className={styles.metadataDetails}>
                  <span>Description</span>
                  <p>{value.description}</p>
                </div>
              </>
            </Collapse.Panel>
          </Collapse>
        ))}

      <Modal
        visible={detailsModalVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setDetailsModalVisible(false)}
        destroyOnClose
        footer={null}
        title={selectedItem ? 'Edit translation' : 'Add translation'}
      >
        <FormGroup
          label="Language"
          errorMessage={errors.selectedLanguage?.message}
          input={
            <Controller
              name="selectedLanguage"
              control={control}
              rules={{ required: 'Required' }}
              codesToExclude={selectedLanguages}
              onChoose={(langCode: string) => {
                setValue('selectedLanguage', langCode);
              }}
              chosenLanguageCode={
                selectedItem
                  ? selectedItem.selectedLanguage
                  : watch('selectedLanguage')
              }
              as={LanguageChooser}
            />
          }
        />
        <FormGroup
          label="Name"
          errorMessage={errors?.metaDataName?.message}
          input={
            <Controller
              as={Input}
              control={control}
              name="metaDataName"
              defaultValue={selectedItem?.name || ''}
            />
          }
        />

        <FormGroup
          label="Description"
          errorMessage={errors?.metaDataDescription?.message}
          input={
            <Controller
              as={TextArea}
              control={control}
              name="metaDataDescription"
              defaultValue={selectedItem?.description || ''}
            />
          }
        />

        <ButtonsRow>
          <Button onClick={() => setDetailsModalVisible(false)} type="default">
            Cancel
          </Button>
          <Button onClick={handleSave} type="primary">
            Save
          </Button>
        </ButtonsRow>
      </Modal>
    </>
  );
};

export default React.memo(MetaData);
