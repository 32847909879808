import React from 'react';
import { Input, InputNumber, Radio, Select } from 'antd';
import FormGroup from 'components/FormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import { DiscountType } from 'types/promotions';
import { promotionTypes } from 'utils/constants';
import { PaymentGateway } from 'types/plan';
import styles from './index.module.scss';

const PromotionModalFields = () => {
  const { errors, control, watch } = useFormContext();
  const gatewayType = watch('gatewayType');
  const discountType = watch('discountType');

  return (
    <>
      <FormGroup
        label="Payment Gateway"
        input={
          <Controller
            render={(field) => (
              <Select
                value={field.value}
                onChange={(value) => field.onChange(value)}
                options={[
                  { label: 'Others', value: null },
                  { label: 'Razorpay', value: PaymentGateway.RAZORPAY },
                ]}
                style={{ width: '100%' }}
              />
            )}
            name="gatewayType"
            defaultValue={null}
            control={control}
          />
        }
      />
      <FormGroup
        errorMessage={errors.administrativeName?.message}
        label="Administrative name"
        input={
          <Controller
            defaultValue=""
            as={<Input placeholder="Administrative name" />}
            control={control}
            name="name"
            rules={{ required: 'Please provide Administrative name' }}
          />
        }
      />

      <FormGroup
        label="Type"
        input={
          <Controller
            render={(field) => (
              <Radio.Group
                className={styles.flex}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                options={promotionTypes}
              />
            )}
            name="discountType"
            defaultValue={promotionTypes[0].value}
            control={control}
          />
        }
      />

      {discountType === DiscountType.PERIOD && (
        <FormGroup
          errorMessage={errors.amount?.message}
          label="Period (days)"
          input={
            <Controller
              as={
                <InputNumber placeholder="Period" type="number" precision={0} />
              }
              defaultValue={0}
              min={0}
              control={control}
              name="amount"
              rules={{ required: 'Please enter correct period value' }}
            />
          }
        />
      )}

      {gatewayType === PaymentGateway.RAZORPAY && (
        <>
          <FormGroup
            errorMessage={errors.externalId?.message}
            label="External ID"
            input={
              <Controller
                defaultValue=""
                as={<Input placeholder="External ID" />}
                control={control}
                name="externalId"
                rules={{ required: 'Please provide External ID' }}
              />
            }
          />
          <FormGroup
            label="Payment Method"
            input={
              <Controller
                render={(field) => (
                  <Select
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    options={[
                      { label: 'UPI', value: 'UPI' },
                      { label: 'Card', value: 'CARD' },
                    ]}
                    style={{ width: '100%' }}
                  />
                )}
                name="paymentMethod"
                defaultValue="UPI"
                control={control}
              />
            }
          />
        </>
      )}
      {discountType === DiscountType.PERCENTAGE && (
        <FormGroup
          errorMessage={errors.amount?.message}
          label="Discount percentage (%)"
          input={
            <Controller
              as={
                <InputNumber
                  placeholder="Value"
                  type="number"
                  precision={0}
                  min={0}
                  max={100}
                />
              }
              defaultValue={0}
              control={control}
              name="amount"
              rules={{ required: 'Please enter correct discount value' }}
            />
          }
        />
      )}

      {discountType === DiscountType.AMOUNT && (
        <FormGroup
          errorMessage={errors.amount?.message}
          label="Discount amount"
          input={
            <Controller
              as={
                <InputNumber
                  precision={2}
                  placeholder="Value"
                  type="number"
                  min={0}
                />
              }
              defaultValue={0}
              control={control}
              name="amount"
              rules={{ required: 'Please enter correct discount value' }}
            />
          }
        />
      )}
    </>
  );
};

export default PromotionModalFields;
