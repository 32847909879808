import { Pagination } from 'types';
import {
  BasicPromotion,
  Coupon,
  GetPromotionsPaginationProps,
  Promotion,
} from 'types/promotions';
import { PAGINATION_SIZE } from 'utils/constants';
import { pageToSkip } from 'utils/pagination';
import api from './api';

export const createPromotion = async ({
  form,
}: {
  form: BasicPromotion;
}): Promise<Promotion> => {
  const { data } = await api().post('/promotion', form);
  return data;
};

export const getPromotionsPagination = async ({
  query,
  page = 1,
  limit = PAGINATION_SIZE,
  promotionType,
  gatewayType,
}: GetPromotionsPaginationProps): Promise<Pagination<Promotion>> => {
  const { data } = await api().get(`/promotion`, {
    params: {
      name: query,
      skip: pageToSkip(page, limit),
      limit: limit,
      promotionType,
      gatewayType: gatewayType,
    },
  });
  return data;
};

export const getPromotion = async ({
  uuid,
}: {
  uuid: string;
}): Promise<Promotion> => {
  const { data } = await api().get(`/promotion/${uuid}`);
  return data;
};

export const updatePromotion = async ({
  promotion,
  uuid,
}: {
  uuid: string;
  promotion: Promotion;
}): Promise<Promotion> => {
  const { data } = await api().put(`/promotion/${uuid}`, promotion);

  return data;
};

export const deletePromotion = async ({
  uuid,
}: {
  uuid: string;
}): Promise<Promotion> => {
  const { data } = await api().delete(`/promotion/${uuid}`);
  return data;
};

export const publishPromotion = async (uuid: string): Promise<Promotion> => {
  const { data } = await api().put(`/promotion/${uuid}/publish`);
  return data;
};

export const unpublishPromotion = async (uuid: string): Promise<Promotion> => {
  const { data } = await api().put(`/promotion/${uuid}/unpublish`);
  return data;
};

export const getPromotionCouponsPagination = async ({
  promotionUuid,
  page,
  couponsCount,
}: {
  promotionUuid: string;
  page?: number;
  couponsCount?: number;
}): Promise<Pagination<Coupon>> => {
  const params = {
    skip: couponsCount ? 0 : pageToSkip(page),
    limit: couponsCount ? couponsCount : PAGINATION_SIZE,
  };

  const { data } = await api().get(`/promotion/${promotionUuid}/coupon`, {
    params,
  });

  return data;
};

export const createPromotionCoupon = async ({
  form,
  promotionUuid,
}: {
  form: Coupon;
  promotionUuid: string;
}): Promise<Coupon> => {
  const { data } = await api().post(`/promotion/${promotionUuid}/coupon`, form);
  return data;
};

export const generateMultiplePromotionCoupons = async ({
  quantity,
  promotionUuid,
}: {
  quantity: number;
  promotionUuid: string;
}): Promise<Coupon> => {
  const { data } = await api().post(
    `/promotion/${promotionUuid}/coupon/generate`,
    {
      quantity,
    }
  );
  return data;
};

export const removeCoupon = async (couponUuid: string): Promise<Coupon> => {
  const { data } = await api().delete(`/coupon/${couponUuid}`);
  return data;
};
