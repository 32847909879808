import { PromotionType } from '@laminar-product/client-commons-core/core';
import { PaymentGateway } from './plan';

export interface BasicPromotion {
  name: string;
  discountType: DiscountType;
  amount?: number;
  startDate: string;
  endDate: string;
  promotionType: PromotionType;
  gatewayType: PaymentGateway;
  externalId?: string;
  paymentMethod?: string;
}

export type GetPromotionsPaginationProps = {
  query?: string;
  page: number;
  limit?: number;
  promotionType?: PromotionType;
  gatewayType?: PaymentGateway;
};

export interface Promotion {
  uuid: string;
  name: string;
  discountType: DiscountType;
  promotionType: PromotionType;
  startDate: Date | string;
  endDate: Date | string;
  amount?: number;
  coupons?: Coupon[];
  status: string;
  gatewayType?: PaymentGateway;
  externalId?: string;
  paymentMethod?: string;
  metadata?: {
    [selectedLanguage: string]: {
      name: string;
      description: string;
    };
  };
}

export interface PromotionForm {
  name: string;
  discountType: DiscountType;
  promotionType: PromotionType;
  startDate: Date | string;
  endDate: Date | string;
  amount?: number;
  coupons?: Coupon[];
  gatewayType?: PaymentGateway;
  externalId?: string;
  paymentMethod?: string;
  metadata?: {
    [selectedLanguage: string]: {
      name: string;
      description: string;
    };
  };
}

export enum Period {
  DAY = 1,
  WEEK = 7,
  MONTH = 30,
  YEAR = 365,
}

export enum DiscountType {
  PERIOD = 'PERIOD',
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export interface Coupon {
  uuid: string;
  type: CouponType;
  code: string;
  quantity: number;
  usedCount: number;
}

export enum CouponType {
  MULTI = 'MULTI',
  SINGLE = 'SINGLE',
}

export enum PromotionRoutes {
  INTERNAL = 'internal',
  AFFILIATE = 'affiliate',
}

export enum CouponKeys {
  uuid = 'uuid',
  type = 'type',
  code = 'code',
  quantity = 'quantity',
}

export enum PromotionStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface CouponCSV {
  uuid: string;
  type: CouponType;
  code: string;
  quantity: number;
  promotionName: string;
  discountType: DiscountType;
  startDate: string;
  endDate: string;
  duration?: Period;
  amount?: number;
}
