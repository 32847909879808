import FormContainer from 'components/FormContainer';
import PageCard from 'components/PageCard';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  DiscountType,
  Promotion,
  PromotionForm,
  PromotionStatus,
} from 'types/promotions';
import {
  GlobalOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SafetyOutlined,
} from '@ant-design/icons';
import Button from 'components/Button';
import moment from 'moment';
import { PublishButton } from 'components/PublishButton';
import {
  usePromotionPublish,
  usePromotionSave,
  usePromotionUnpublish,
} from '../promotionUtils/promotions';
import PromotionsValidity from './PromotionsValidity';
import PromotionGeneralDetails from './PromotionsGeneralDetails';
import styles from './index.module.scss';
import PromotionMetaData, {
  CurrentItem,
  MetaDataItems,
} from './PromotionMetaData';

interface PromotionDetailsFormProps {
  promotion?: Promotion;
  setPageHeader: (header: string) => void;
  refresh: () => void;
}

const PromotionDetails = ({
  promotion,
  setPageHeader,
  refresh,
}: PromotionDetailsFormProps) => {
  const [detailsModalVisible, setDetailsModalVisible] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<
    CurrentItem | undefined
  >(undefined);
  const [items, setItems] = React.useState<MetaDataItems | undefined>(
    promotion?.metadata
  );
  const formMethods = useForm<PromotionForm>({ mode: 'onChange' });
  const { handleSubmit, getValues, reset, watch } = {
    ...formMethods,
  };
  const { save, isSaving } = usePromotionSave(
    promotion?.uuid!,
    promotion?.promotionType!
  );

  const { saveAndpublish, isPublishing } = usePromotionPublish(
    promotion?.uuid!,
    promotion?.promotionType!,
    refresh
  );

  const { unpublish, isUnpublishing } = usePromotionUnpublish(
    promotion?.uuid!,
    refresh
  );
  useEffect(() => {
    if (!promotion) return;

    if (!getValues('name')) {
      reset({
        ...promotion,
        amount:
          promotion.discountType === DiscountType.AMOUNT
            ? (promotion.amount || 0) / 100
            : promotion.amount,
        startDate: moment(promotion.startDate),
        endDate: moment(promotion.endDate),
      });
    }
  }, [getValues, reset, promotion]);

  useEffect(() => {
    const promotionName = watch('name');
    if (promotionName) {
      setPageHeader(promotionName);
    }
  }, [watch, setPageHeader, promotion]);

  useEffect(() => {
    setItems(
      (prevItems: MetaDataItems | undefined) =>
        ({
          ...prevItems,
          ...promotion?.metadata,
        } as MetaDataItems)
    );
  }, [promotion?.metadata]);

  return (
    <>
      <div className={styles.fullWidth}>
        <FormContainer>
          <FormProvider {...formMethods}>
            <form>
              <PageCard title="General details" icon={<InfoCircleOutlined />}>
                <PromotionGeneralDetails status={promotion?.status!} />
              </PageCard>

              <PageCard
                title="Public details"
                icon={<GlobalOutlined />}
                extra={
                  <Button
                    type="primary"
                    ghost
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setDetailsModalVisible(true);
                      setSelectedItem(undefined);
                    }}
                  >
                    Add language
                  </Button>
                }
              >
                <PromotionMetaData
                  setDetailsModalVisible={setDetailsModalVisible}
                  detailsModalVisible={detailsModalVisible}
                  promotion={promotion}
                  setItems={setItems}
                  items={items}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              </PageCard>
              <PageCard title="Validity" icon={<SafetyOutlined />}>
                <PromotionsValidity />
              </PageCard>

              <div className={styles.modalButtons}>
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={handleSubmit(save)}
                  loading={isSaving}
                >
                  Save
                </Button>

                {promotion?.status === PromotionStatus.PUBLISHED ? (
                  <>
                    <PublishButton
                      loading={isUnpublishing || isPublishing}
                      onClick={handleSubmit(saveAndpublish)}
                      text="Save and Republish"
                    />
                    <PublishButton
                      ghost
                      danger
                      noIcon
                      loading={isUnpublishing || isPublishing}
                      onClick={() => unpublish()}
                      text="Unpublish"
                    />
                  </>
                ) : (
                  <PublishButton
                    loading={isPublishing}
                    onClick={handleSubmit(saveAndpublish)}
                    text="Save and publish"
                  />
                )}
              </div>
            </form>
          </FormProvider>
        </FormContainer>
      </div>
    </>
  );
};

export default PromotionDetails;
